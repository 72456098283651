<template>
    <div></div>
</template>
<script>
import axios from 'axios'
import md5 from 'js-md5';
import ElementUI from 'element-ui';
import {
    getUrlKey,web_inter,yooz_url,urlfix,setLocalItem
} from '@/untils/common';
import loading from '@/components/loading'
export default {
    name: 'googleoauth',
    data () {
        return {
        }
    },
    methods: {
        getCode () {
            const code = getUrlKey('code')
            const local = window.location.href;
            var dz_url = local.split('?')[1];//获取#/之前的字符串
            if(dz_url){
                var cs_arr = dz_url.split('&');//获取?之后的参数字符串
                if(cs_arr){
                    var cs={};
                    for(var i=0;i<cs_arr.length;i++){//遍历数组，拿到json对象
                        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
                    }
                    if(cs.code){
                        if(localStorage.lang=='en'){
                            loading.showLoading({title:'Loading'})
                        }else if(localStorage.lang=='cn'){
                            loading.showLoading({title:'加载中'})
                        }else {
                            loading.showLoading({title:'Caricamento'})
                        } 
                        // loading.showLoading({title:'加载中'})
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        var urlencoded = new URLSearchParams();
                        urlencoded.append("code", code);
                        urlencoded.append("client_id", web_inter.google.client_id);
                        urlencoded.append("client_secret", web_inter.google.client_secret);
                        urlencoded.append("redirect_uri", web_inter.google.google_url);
                        urlencoded.append("grant_type", "authorization_code");
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                        };
                        if(requestOptions){
                            fetch("https://oauth2.googleapis.com/token", requestOptions)
                            .then(response => response.text())
                            .then((result) => {
                                if(result){
                                    let results = JSON.parse(result);
                                    if(results.access_token){
                                        axios.get('https://www.googleapis.com/oauth2/v1/tokeninfo',{  
                                            params:{    
                                                access_token:results.access_token  
                                            }
                                        }).then(function(resp){  
                                            if(resp){
                                                if(resp.data){
                                                    let clientid = web_inter.google.client_id;//平台唯一id301546955432179
                                                    let clientsecret = web_inter.google.client_secret;//平台唯一秘钥e770ae2bb5737aa0fe9892a249e4bf92
                                                    let timestamp = new Date().getTime();
                                                    let fappid = resp.data.user_id;//用户在平台唯一userid
                                                    let fsign = md5(clientid+clientsecret+timestamp+fappid);
                                                    let params = {
                                                        time:timestamp,//	时间戳	
                                                        sign:fsign,//	签名 var timestamp=new Date().getTime(); $clientid = 'AIzaSyCDNBuNBH5BRGCr5JQiziIqvTCTJSbB5uo'; $gid ="g"+timestamp;//实际为goole返回的唯一平台id $gsign = md5($clientid+timestamp+$gid);	
                                                        id:fappid,//	goole返回的唯一平台id	
                                                        nickname:resp.data.email,//	昵称	
                                                        head_pic:'',//	头像路径	
                                                        access_token:results.access_token,//	access_token	
                                                        union_id:'',//	唯一id
                                                    }
                                                    axios.post(yooz_url+urlfix.prop+'login/goolelogin',params).then((res)=>{
                                                        if(res&&res.data&&res.data.data){
                                                            let response = res.data;
                                                            if(response.code==1){
                                                                if(response.data){
                                                                    loading.cancelLoading();
                                                                    if(response.data.ubid){
                                                                        if(response.data.userinfo.length==0){
                                                                            if(results.access_token){
                                                                                window.location.replace(yooz_url+'uinfo?type=2&ubid='+response.data.ubid)
                                                                            }
                                                                        }else if(response.data.userinfo&&response.data.userinfo.token){
                                                                            setLocalItem('login_user_info',response.data.userinfo);
                                                                            /*account: "13333333333"
                                                                                family_name: null
                                                                                head_pic: ""
                                                                                id: 86
                                                                                name: null
                                                                                nickname: ""
                                                                                token: "92a7cce90e0100c052be8bddd9276cfc*/ 
                                                                            if(response.data.userinfo.family_name==null&&response.data.userinfo.token!=''){
                                                                                window.location.replace(yooz_url+'uinfo?type=1');
                                                                            }else{
                                                                                location.href = yooz_url
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }else{
                                                                ElementUI.Message({
                                                                    message:res.message,
                                                                    type:'error',
                                                                    center: true,
                                                                    offset:10,
                                                                });
                                                                return false;
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }).catch(function(err){  
                                            console.log(err);
                                        });
                                    }
                                }
                            }).catch(error => console.log('error', error));
                        }
                    }
                }
            }
        }
    },
    created () {
        // 授权方法
        this.getCode()
    }
}
</script>
<!-- 
/*接口地址:https://www.googleapis.com/oauth2/v1/userinfo
接口参数:access_token:就是上面获取的token
构建好的地址是:https://www.googleapis.com/oauth2/v1/userinfo?access_token=AAA*/  
-->
<!-- 第一种：

首先用户在客户端上点击要用哪个系统的OAuth2来认证，此时客户端附上回调地址
用户在OAuth2服务器上选择是否授权用户给予授权，OAuth2服务器重定向到第一步给定的回调地址，同时附上 Authorization Code，
回调地址所在服务器带上 Authorization Code和client_id，向OAuth2服务器申请 access_token 和 refresh_token，可通过这两个token去换取资源
OAuth2服务器返回 access_token 和 refresh_token
第二种：

用户在客户端上点击要哪个系统的OAuth2来认证，此时客户端附上回调地址
用户在OAuth2服务器上选择是否授权
用户给于授权，OAuth2服务器重定向到第一步给定的回调地址，并且附上 access_token 和 refresh_token
第三种： 用户直接输入用户名和密码，这种情况针对自家的APP或者100%信任的APP可以这么干

第四种： 客户端自带认证，用户向客户端认证就可以 -->